<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
        <el-button type="success" size="small" @click="showSyncSuppDataDialog" icon="el-icon-refresh">同步</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'supp_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getSuppsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
      <el-dialog title="同步供应商信息" width="70%" top="0" :visible.sync="syncSuppDialogVisible" @opened="getSyncSuppData">
        <search-table
          v-if="syncSuppDialogVisible"
          ref="syncSuppTable"
          :tableRowKey="`autoInc`"
          :data="syncSuppTableData"
          :totalPage="syncSuppTotalPage"
          :columns="syncSuppTableProperties"
          v-loading="syncLoadFlag"
          @row-dblclick="syncSuppData"
          @getTableData="getSyncSuppData"
          :need-fixed-height="true"
        />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { tableProperties } from '@/views/MessageManagement/SuppManage/supp';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { syncSuppTableProperties } from '@/views/MessageManagement/SuppManage/supp';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'SuppList',
  components: {
    SearchTable
  },
  data() {
    return {
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: false,
      multiSelection: [],
      currentPage: 1,
      syncSuppDialogVisible: false,
      syncSuppTableData: [],
      syncSuppTotalPage: 0,
      syncSuppTableProperties: syncSuppTableProperties,
      syncLoadFlag: true
    };
  },
  mounted() {
    if (this.$route.query.supp_id) {
      this.$refs.multiTable.searchForm.supp_id = Number(this.$route.query.supp_id);
    }
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/supp_add' || from.path === '/supp_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getSuppsList();
    },
    showSyncSuppDataDialog() {
      this.syncSuppDialogVisible = true;
    },
    syncSuppData(row) {
      this.syncSuppDialogVisible = false;
      this.$router.push({
        name: 'SuppAdd',
        query: { perm_id: this.$route.query.perm_id, autoInc: row.rich_erp_id },
        params: { syncSuppData: row }
      });
    },
    async getSyncSuppData() {
      await (this.syncLoadFlag = true);
      await suppAPI.getRichErpSupp(this.$refs.syncSuppTable.searchForm).then(({ data }) => {
        this.syncSuppTableData = data.list;
        this.syncSuppTotalPage = data.total;
        this.syncLoadFlag = false;
      });
    },
    // 获取供应商信息
    async getSuppsList() {
      await (this.loadFlag = true);
      await getNoCatch(suppAPI.getSupps, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getSuppsList();
    },
    // 查询方法
    getSuppsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.searchForm.page_no = 1;
      this.getSuppsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/supp_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: permId,
            form_id: row.supp_id
          })
        )
      });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/supp_add?perm_id=${permId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getSuppsList();
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //供应商信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.supp_id);
          });
          post(suppAPI.deleteSuppByIds, { supp_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
