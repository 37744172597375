import store from '@/store';

const formatterSuppType = val => {
  switch (val) {
    case 1:
      return '加工厂';
    case 2:
      return '面料厂';
    case 3:
      return '配套厂';
    case 4:
      return '辅料厂';
  }
};
export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '累计订单量', prop: 'supp_ordr_total', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '供应商编号', prop: 'supp_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  {
    label: '供应商类型',
    prop: 'supp_type',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    formatter: val => formatterSuppType(val)
  },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '供应商全称', prop: 'supp_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '供应商地址', prop: 'supp_addr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '主营类目',
    prop: 'supp_maincate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true
  },
  { label: '联系人', prop: 'supp_contact', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { prop: 'stff_name', label: '采购经办人', itemType: 'input', labelWidth: '120px', sortable: false },
  store.state.create_time,
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];
export const syncSuppTableProperties = [
  store.state.index,
  { label: '供应商编号', prop: 'supp_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '供应商全称', prop: 'supp_name', itemType: 'input', input: true, sortable: false, widthAuto: true }
];
